let amchart_lang = [];

amchart_lang['en'] = {};

amchart_lang['ua'] = {
    "Jan": "Січ",
    "Feb": "Лют",
    "Mar": "Бер",
    "Apr": "Квіт",
    "May(short)": "Трав",
    "Jun": "Черв",
    "Jul": "Лип",
    "Aug": "Серп",
    "Sep": "Вер",
    "Oct": "Жовт",
    "Nov": "Лист",
    "Dec": "Груд"
};

amchart_lang['ar'] = {
    "Jan": "يناير",
    "Feb": "فبراير",
    "Mar": "مارس",
    "Apr": "أبريل",
    "May(short)": "مايو",
    "Jun": "يونيو",
    "Jul": "يوليو",
    "Aug": "أغسطس",
    "Sep": "سبتمبر",
    "Oct": "أكتوبر",
    "Nov": "نوفمبر",
    "Dec": "ديسمبر"
};

export {amchart_lang};