

// Show image when upload | add remove button

export var loadFile = function(event, id) {
var reader = new FileReader();
reader.onload = function(){
  var output = document.getElementById('output-' + id);
  output.src = reader.result;

  var removeButton = document.getElementById("removeImage-" + id);
  removeButton.setAttribute("type", "button");

  var uploadButton = document.getElementById("uploadImage-" + id);
  uploadButton.classList.add("hidden", "d-none");

  var image = document.getElementById('output-' + id);
  image.classList.remove("hidden");

};
reader.readAsDataURL(event.target.files[0]);
};


// Remove image
export function removeButton(id) {
  var image = document.getElementById('output-' + id);
  image.removeAttribute("src");
  image.classList.add("hidden");

  var removeButton = document.getElementById("removeImage-" + id);
  removeButton.setAttribute("type", "hidden");

  document.getElementById('uploadImage-' + id).value = "";

  var uploadImage = document.getElementById("uploadImage-" + id);
  uploadImage.classList.remove("hidden", "d-none");
}


// Remove uploaded file
export function removeFileButton(id) {
  var image = document.getElementById('output-' + id);
  image.removeAttribute("src");
  image.classList.add("hidden");
  
  var removeButton = document.getElementById("removeFile-" + id);
  removeButton.setAttribute("type", "hidden");
  
  document.getElementById('uploadFile-' + id).value = "";
  
  var uploadFile = document.getElementById("uploadFile-" + id);
  uploadFile.classList.remove("hidden", "d-none");
}






 






