$(function() {

    let $organisation = $('.organisation');
    let $organisation_level_btn = $('.organisation .organisation-level .expand'), 
        $organisation_linked_btn = $('.organisation .show-linked'), 
        $organisation_reset_btn = $('.organisation .organisation-level .reset');
    let $organisation_obj = $('.organisation-panel');
    var $selected_parent = 0;
    
    /* OPEN / CLOSE LEVELS */
    $organisation_level_btn.on('click', function($event, $auto_run = true){
        $event.preventDefault();
        // console.log('>> organisation_level_btn click', $auto_run)
        let $level = $(this).data('level');
        let $org_level_target = $organisation.find(".organisation-level-cont[data-org-level='" + $level + "']");

        $(this).toggleClass('expanded');

        if ( $(this).hasClass('expanded') ){
            $(this).find('i').removeClass('fa-plus').addClass('fa-minus');
            $org_level_target.slideDown({
                start: function () {
                  $(this).css({
                    display: "flex"
                  })
                }
            });
            if ( $auto_run ){
                $('html, body').delay(50).animate({ scrollTop: $(this).offset().top - 25 }, 250);
            }
        } else {
            if ( $auto_run ){
                $org_level_target.slideUp();
                $(this).find('i').removeClass('fa-minus').addClass('fa-plus');
            }
        }

    });

    /* SEARCH */

    /* FIND LINKED ORGANISATIONS */
    $organisation_linked_btn.on('click', function(event){
        event.preventDefault();
        let $org_id = $(this).data('org-id');
        let $current_level = $(this).closest('.organisation-level-cont').data('org-level');
        let $levels = $organisation.find('.organisation-level-cont');
        let $org_cont = $(this).closest('.panel')
        let $related_children = [];
        let $next_level = null;
        
        // update the clicked organsation
        $organisation.find(".organisation-level-cont[data-org-level='" + $current_level + "'] .organisation-panel").removeClass('panel-dark-mid-grey');
        $org_cont.removeClass('panel-white').addClass('panel-dark-mid-grey');

        // recurvely find all the related children
        $.fn.findChildren( $org_id, $related_children )

        // console.table( {"org_id": $org_id});
        // console.table( $related_children);

        // clean up if anything was previous filtered
        $organisation.find(".organisation-level-cont .organisation-panel.highlight").removeClass('highlight');

        // find the correct child elements
        $levels.each(function(){
            let $_level = $(this).data('org-level');
            let $_level_ele = $organisation.find(".organisation-level[data-org-level='" + $_level + "']"),
                $_level_cont_ele = $organisation.find(".organisation-level-cont[data-org-level='" + $_level + "']");

            // ignore levels above
            if ( $_level > $current_level ){
                // clear out any existing filters
                $_level_ele.find('span.filter').text('');
                $_level_ele.find('a.reset').addClass('d-none').find('span').text('');
                $_level_cont_ele.find('.organisation-panel').removeClass('panel-dark-mid-grey').addClass('panel-white').parent().removeClass('filted-out');

                for ( $i in $related_children){
                    let $ele = $organisation.find(".organisation-level-cont[data-org-level='" + $_level + "'] .organisation-panel[data-org-id='" +  $related_children[$i] + "']");
                    $ele.addClass('highlight');
                }
                 // now hide anyone we do not want to highlight
                $organisation.find(".organisation-level-cont[data-org-level='" + $_level + "'] .organisation-panel").not(".highlight").parent().addClass('filted-out');

                 // find the next level
                if ( $next_level == null ){
                    $next_level = $_level;
                }
            }
        });

        // open up all levels
        $organisation_level_btn.trigger('click', [false]);
        // scroll to next level & show the clear buttons
        if ( $next_level != 0 ){
            let $next_level_title = $organisation.find("#sub-level-" + $next_level);
            let $next_level_cont = $organisation.find(".organisation-level-cont[data-org-level='" + $next_level + "']");
            if ( $next_level_title.length && $next_level_cont.length ){
                let $filter_title = $next_level_title.find('h4 span.title').text();
                $next_level_title.find('span.filter').text( $(this).closest('.organisation-panel').find('h3').text() );
                $next_level_title.find('a.reset').removeClass('d-none').find('span').text( $('.lang_view').val() + " " + $filter_title );
                $('html, body').delay(100).animate({ scrollTop: $next_level_title.offset().top - 15 }, 250);
            }
        }
    });

    $.fn.findChildren = function( $org_id, $related_children ){
        // console.log( ">>findChildren", $org_id, $related_children);
        let $children = $(".organisation-panel[data-org-parent-id='" + $org_id + "']");

        $children.each(function(){
            let $_child_org_id = $(this).data('org-id');
            $related_children.push( $_child_org_id );
            $.fn.findChildren($_child_org_id, $related_children)
        });
    }

    $organisation_reset_btn.on('click', function($event){
        $event.preventDefault();

        let $current_level_ele = $(this).closest('.organisation-level'),
            $current_level = $current_level_ele.data('org-level'),
            $current_level_cont = $organisation.find(".organisation-level-cont[data-org-level='" + $current_level + "']");
        let $previous_level_ele = $(".organisation-level[data-org-level='" + ($current_level-1) + "']"),
            $previous_level = $previous_level_ele.data('org-level'),
            $previous_level_cont = $organisation.find(".organisation-level-cont[data-org-level='" + $previous_level + "']");

console.table(($current_level-1), $previous_level_ele, $previous_level_ele.data('org-level'), $previous_level)

        // clear previous level
        $previous_level_cont.find('.organisation-panel').removeClass('panel-dark-mid-grey').addClass('panel-white');

        // clear current level
        $current_level_ele.find('span.filter').text('');
        $current_level_ele.find('a.reset').addClass('d-none').find('span').text('');
        $current_level_cont.find('.filted-out').removeClass('filted-out');


    });

});