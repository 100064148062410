$(function() {

    $(window).on('resize', function(){
        $('.form-group').each(function() {
            var formGroup = $(this),
                formgroupWidth = formGroup.width();
            
            formGroup.find('.select2-container').css('width', formgroupWidth);
            
        });
    });

});