import toastr from 'toastr';
import Dropzone from 'dropzone';
import {loadFile, removeButton, removeFileButton} from './dm-components/upload';
import Chart from 'chart.js';
import PDFObject from 'pdfobject';
import Swal from 'sweetalert2/dist/sweetalert2.js'

import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import 'datatables.net';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import './components/datepicker-langs';
import './components/inspinia';

// AMCHARTS
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import {amchart_lang} from './components/amchart5_lang';
window.amchart_lang = amchart_lang;

window.toastr = toastr;
window.Dropzone = Dropzone;
window.loadFile = loadFile;
window.removeButton = removeButton;
window.removeFileButton = removeFileButton;
window.Chart = Chart;
window.PDFObject = PDFObject;
window.Swal = Swal;

// AMCHARTS
window.am5 = am5;
window.am5xy = am5xy;
window.am5percent = am5percent;
window.am5sequenceColours = [
    am5.color(0x5cc9a5),
    am5.color(0xf74747),
    am5.color(0xFEC700),
    am5.color(0xffa700),
    am5.color(0x060f29),
    am5.color(0x8eaebc),
    am5.color(0x169dff),
    am5.color(0x5a607f),
    am5.color(0xd045b0),
    am5.color(0xa2e9ed),
];

// COMPONENETS
import './dm-components/upload';
import './dm-components/datatables';
import './dm-components/tabs';
import './dm-components/select';
import './dm-components/side-panel';
import './dm-components/organisations';
