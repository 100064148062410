$(function() {
    /* ADD BROWSER HASH FOR TABS */
    $('button[data-bs-toggle="tab"]').historyTabs();

    /* FIX BUG THAT CAUSES TABLE TO NOT BE RESPONSIVE IF FIRST TAB HAS NO TABLE */
    $('button[data-bs-toggle="tab"]').on('click', function(event){
        $('.tab-pane.active table.datatable-auto').DataTable().columns.adjust().responsive.recalc();
        $(window).trigger('resize');
    });

});