$(function(){
    $.fn.moveDatetablesFields = function( $target_name = 'body'){
        let $target = $($target_name);
        if ( $target.find('.datatables-show').length ){
            $target.find('div.dataTables_length').each(function(index){
                $(this).appendTo( $(this).closest('.panel').find('.datatables-show') )
                $(this).append('<hr class="seperator">');
            });
        }
        if ( $target.find('.datatables-search').length ){
            $target.find('div.dataTables_filter').each(function(index){
                $(this).appendTo( $(this).closest('.panel').find('.datatables-search') )
                $(this).append('<hr class="seperator">');
            });
        }
    }

    setTimeout( $.fn.moveDatetablesFields, 250 );
});