$(function() {

    let $side_panel_name = '#side-panel-modal', $side_panel = $($side_panel_name);
    
    $(window).on('shown.bs.modal', function() { 
        $(window).trigger('resize');
    });

    $(window).on('hidden.bs.modal', function() { 
        $side_panel.find('.modal-dialog').removeClass('modal-xl');
    });
    

    $("body").on('click', '.side-panel-request', function(event){
        event.preventDefault();
        let $request = $(this).data('request');

        let api_token = $('meta[name="api_token"]').attr('content');

        if ( $(this).hasClass('side-panel-modal-xl') ){
            $side_panel.find('.modal-dialog').addClass('modal-xl');
        } else {
            $side_panel.find('.modal-dialog').removeClass('modal-xl');
        }

        if ( $(this).hasClass('side-panel-request-dark') ){
            $side_panel.find('.modal-dialog').addClass('modal-dialog-dark').find('.btn-close').addClass('btn-close-white');
        } else {
            $side_panel.find('.modal-dialog').removeClass('modal-dialog-dark').find('.btn-close').removeClass('btn-close-white');
        }
        
        $.ajax({
            type: 'GET',
            data: {
                api_token: api_token
            },
            url: $request,
            success: function($response){
                $side_panel.find(".modal-body").html( $response );
                $side_panel.modal('show');
                setTimeout(function() {
                    $.fn.moveDatetablesFields( $side_panel_name );
                }, 250);

                $('form').not('.delete-form').on('submit', function(e){
                    e.preventDefault();

                    let $form = $(this);

                    // Get all data from the form
                    let $data = new FormData( document.getElementById( $form.attr('id') ) );

                    // Attache api_token to params
                    $data.append('api_token', api_token);

                    // Get link for the POST request
                    let $post_request = $form.attr('action');

                    $.ajax({
                        type: 'POST',
                        data: $data,
                        url: $post_request,
                        processData: false,
                        contentType: false,
                        async: false,
                        success: function(response){
                            console.log('here');
                            let $response = JSON.parse(response);
                            if($response.status != 'success'){
                                // Handle server errors
                                $form.find('.form-group').removeClass('has-error');
                                $form.find('.help-block').html('');

                                $.each($response, function(index, value) {
                                    $form.find('#'+index).closest('.form-group').addClass('has-error');
                                    $form.find('#'+index).closest('.form-group').find('.help-block').html(value[0]);
                                    toastr.error(value[0], '', {timeOut: 3000})
                                });

                                $('#side-panel-modal .modal-body').animate({ scrollTop: 0 }, 'slow');
                            }
                            else {
                                // If the entry has been added/edited successfully close the modal window and reload page 
                                $side_panel.modal('hide');
                                if($response.redirect){
                                    $(location).attr('href', $response.redirect);
                                }
                                else {
                                    location.reload();
                                }
                                
                            }
                            
                        }
                    })


                });
            }
        })
    });

    $("body").on('click', '.side-panel-modal', function(event){
        event.preventDefault();
        let $modal_id = $(this).data('modal-target');
        let $modal_content = $( $modal_id ).find('>div').clone();

        console.log($modal_id, $modal_content)

        if ( $(this).hasClass('side-panel-modal-xl') ){
            $side_panel.find('.modal-dialog').addClass('modal-xl');
        } else {
            $side_panel.find('.modal-dialog').removeClass('modal-xl');
        }

        if ( $(this).hasClass('side-panel-modal-dark') ){
            $side_panel.find('.modal-dialog').addClass('modal-dialog-dark').find('.btn-close').addClass('btn-close-white');
        } else {
            $side_panel.find('.modal-dialog').removeClass('modal-dialog-dark').find('.btn-close').removeClass('btn-close-white');
        }

        $side_panel.find(".modal-body").html( $modal_content );
        $side_panel.modal('show');
        
        /*$('form').not('.delete-form').on('submit', function(e){
            e.preventDefault();

            let $form = $(this);

            // Get all data from the form
            let $inputs = $form.find(':input');

            let $data = {};
            $inputs.each(function() {
                $data[this.name] = $(this).val();
            });
            // Attache api_token to params
            $data['api_token'] = api_token;

            // Get link for the POST request
            let $post_request = $form.attr('action');

            $.ajax({
                type: 'POST',
                data: $data,
                url: $post_request,
                success: function($response){
                    if($response != 'success'){
                        // Handle server errors
                        $form.find('.form-group').removeClass('has-error');
                        $form.find('.help-block').html('');

                        let $errors = JSON.parse($response);

                        $.each($errors, function(index, value) {
                            $form.find('#'+index).parent().addClass('has-error');
                            $form.find('#'+index).parent().find('.help-block').html(value[0]);
                        });

                        $('#side-panel-modal .modal-body').animate({ scrollTop: 0 }, 'slow');
                    }
                    else {
                        // If the entry has been added/edited successfully close the modal window and reload page 
                        $side_panel.modal('hide');
                        location.reload();
                    }
                    
                }
            })


        });*/

    });
    

});